import Head from "next/head";
import React from "react";

const Meta = () => (
  <div>
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="theme-color" content="#F53240" />
      <meta property="og:title" content="Dexecure Dashboard | Dexecure" />
      <meta
        property="og:description"
        content="A dashboard for dexecure users."
      />
      <meta property="og:image" content="/static/dexecure-main-thumb.jpg" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <link
        rel="icon"
        type="image/png"
        href="/static/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="/static/favicon-16x16.png"
        sizes="16x16"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Montserrat:400,600,700"
        rel="stylesheet"
      />
    </Head>
    <style jsx global>
      {`
        .ant-card {
          background: none !important;
        }
        .ant-dropdown-menu-item:hover {
          background-color: #fff !important;
        }
        body {
          margin: 0;
          font-family: "Montserrat";
          font-size: 1em;
          background-color: #fff;
        }
        img {
          max-width: 100%;
          height: auto;
        }
        
        ol {
          counter-reset: list;
          list-style: none;
        }

        ol li {
          counter-increment: list;
          margin-bottom: 10px;
          position: relative;
        }

        ol li::before {
          content: counter(list);
          background: #1f5080;
          color: #fff;
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          border-radius: 50%;
          width: 1.6em;
          height: 1.6em;
          line-height: 1.6em;
          display: inline-block;
          left: -3em;
          position: absolute;
          top: 3px;
        }

        .dashboard-style {
          max-width: 72em;
          margin: 0%;
        }
        .pulseloader {
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .btn {
          display: inline-block;
          line-height: normal;
          text-decoration: none;
          text-align: center;
          border: 0;
          cursor: pointer;
          font-size: 1rem;
          border-radius: 4px;
          padding: 10px 20px;
          background: #db3340;
          box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
            0 1px 3px rgba(0, 0, 0, 0.08);
          color: #ffffff;
          transition: 0.3s background-color ease-in-out;
          -webkit-font-smoothing: antialiased;
          opacity: 0.99;
        }
        .btn&:hover,
        .btn&:active {
          transition: 0.3s background-color ease-in-out;
          background-color: #b11622;
        }
        .btn:disabled { 
          opacity: 0.65;       
          cursor: not-allowed;
        }
        .cancel__button {
          background: none;
          border: none;
          cursor: pointer;
        }
        .btn--coupon {
          display: inline-block;
          height: 48%;
          margin-top: 10%;
          margin-left: 2%;
          line-height: normal;
          text-decoration: none;
          text-align: center;
          border: 0;
          cursor: pointer;
          font-size: 0.9rem;
          border-radius: 4px;
          padding: 8px 14px;
          background: #153b58;
          color: #fff;
        }
        .btn--reset {
          margin-top: 1.5em;
        }
        .input-tip {
          color: #6b6565;
          font-style: italic;
          height: 17px;
          display: inline-block;
          max-width: 13em;
        }
        .coupon-tip {
          position: absolute;
          bottom: 20%;
        }
        input:invalid {
          box-shadow: none;
        }
        .tgl {
          display: none;
        }
        .tgl,
        .tgl:after,
        .tgl:before,
        .tgl *,
        .tgl *:after,
        .tgl *:before,
        .tgl + .tgl-btn {
          box-sizing: border-box;
        }
        .tgl::-moz-selection,
        .tgl:after::-moz-selection,
        .tgl:before::-moz-selection,
        .tgl *::-moz-selection,
        .tgl *:after::-moz-selection,
        .tgl *:before::-moz-selection,
        .tgl + .tgl-btn::-moz-selection {
          background: none;
        }
        .tgl::selection,
        .tgl:after::selection,
        .tgl:before::selection,
        .tgl *::selection,
        .tgl *:after::selection,
        .tgl *:before::selection,
        .tgl + .tgl-btn::selection {
          background: none;
        }
        .tgl + .tgl-btn {
          outline: 0;
          display: block;
          width: 1.8em;
          height: 0.9em;
          position: relative;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        .tgl + .tgl-btn:after,
        .tgl + .tgl-btn:before {
          position: relative;
          display: block;
          content: "";
          width: 50%;
          height: 100%;
        }
        .tgl + .tgl-btn:after {
          left: 0;
        }
        .tgl + .tgl-btn:before {
          display: none;
        }
        .tgl[value="on"] + .tgl-btn:after {
          left: 50%;
        }
        .tgl-light + .tgl-btn {
          background: #c3c3c3;
          border-radius: 2em;
          padding: 2px;
          -webkit-transition: all 0.4s ease;
          transition: all 0.4s ease;
        }
        .tgl-light + .tgl-btn:after {
          border-radius: 50%;
          background: #fff;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
        .tgl-light[value="on"] + .tgl-btn {
          background: #153b58;
        }
        .input-group {
          position: relative;
          padding-top: 2.5em;
        }
        .question {
          display: block;
          border: none;
          font-size: 1.2em;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          height: 36px;
          background: transparent;
          width: 100%;
        }
        .question:focus {
          outline: 0;
          transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          transition-property: border-bottom;
          border-bottom: 1px solid #153b58;
        }
        .question-label {
          position: absolute;
          bottom: 3px;
          left: 0;
          z-index: -1;
          color: #626262;
          transition: color 2s cubic-bezier(0.42, 0, 0.58, 1),
            top 2s cubic-bezier(0.42, 0, 0.58, 1),
            bottom 2s cubic-bezier(0.42, 0, 0.58, 1);
          top: 10px;
          color: #153b58;
        }
        .row-group {
          display: flex;
          flex-direction: column;
        }
        @media all and (min-width: 40em) {
          .row-group {
            flex-direction: row;
          }
        }
        // .question:focus + .question-label, .question:valid + .question-label, .question:optional + .question-label {
        // }
        .svg-background {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -999;
          overflow: hidden;
        }
        .svg-background svg {
          height: 100%;
          width: 100%;
          position: relative;
        }
        .outer {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          z-index: -999;
          padding: 1em;
          margin-top: 3em;
        }
        .new__container {
          position: relative;
          padding: 1.5em 2em;
          background-color: rgba(255, 255, 255, 0.6);
          z-index: 0;
          border-radius: 20px;
          margin-top: -15px;
        }
        .text {
          font-size: 1.5em;
          display: block;
          padding: 0.5em 0 1em 0;
        }
        .loader {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .StripeElement {
          font-weight: 400;
          font-family: "Montserrat";
          background-color: #f2f2f2;
          padding: 0.4em;
          border-radius: 4px;
        }
        .payment-group {
          padding-bottom: 1em;
        }
        .user--personal-dashboard a {
          text-decoration: none;
          color: #db3340;
          display: flex;
          align-items: center;
        }
        .user--personal-dashboard a svg {
          width: 20px;
          padding-right: 0.5em;
        }
        .modal__header {
          text-align: center;
          margin-top: -6%;
        }
        .plugin__header {
          text-align: center;
          margin-top: -4%;
        }
        .back-btn {
          display: inline-block;
          width: 15%;
          float: right;
          margin-top: -6%;
          background: none;
          border: none;
          color: #db3340;
          font-size: 19px;
          cursor: pointer;
        }
        .back-btn svg {
          width: 14px;
          height: 14px;
          padding-right: 9px;
        }
        .dashboard-card {
          background-color: #f9fafb !important;
          border-radius: 16px !important;
          width: 100%;
          opacity: "1% !important";
        }
        .dashboard-card .ant-card-body {
          padding: 15px;
          back-ground: #f4f9ff 0% 0% no-repeat padding-box;
          margin-top: 5px;
        }
        .dashboard-card .dashboard-summary-title {
          font-size: 17px;
          color: #174063;
        }
        .dashboard-card .dashboard-summary-usage {
          font-size: 20px;
          color: #174063;
          font-family: "Montserrat";
          font-weight: 600;
          top: 15px;
          position: relative;
        }
        .ant-layout-sider-children {
          background: linear-gradient(85deg, #0a50749c 0%, #156d9612 100%);
          opacity: 1;
        }
        .ant-menu-dark .ant-menu-inline.ant-menu-sub {
          background: linear-gradient(85deg, #0a50749c 0%, #156d9612 100%);
          box-shadow: none !important;
          background: #143651;
        }
        .ant-menu-root.ant-menu-inline {
          background: transparent;
        }
        .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
        .ant-menu.ant-menu-dark .ant-menu-item-selected {
          background-color: transparent;
        }
        .ant-menu-dark .ant-menu-item-active > a,
        .ant-menu-dark .ant-menu-item:hover > a {
          color: #ee3444;
        }
        .ant-menu-dark .ant-menu-item-selected .anticon + span,
        .ant-menu-dark .ant-menu-item-selected .anticon,
        .ant-menu-dark .ant-menu-submenu-open {
          color: #ee3444;
        }
        section.site-layout.ant-layout {
          background: #fff;
        }
        .ant-menu-dark,
        .ant-menu-dark .ant-menu-sub {
          color: hsla(0, 0%, 100%, 0.65);
          background: #042a43 0% 0% no-repeat padding-box;
        }
        .ant-card-body {
          margin-top: -25px;
        }
        .dashboard-background-img {
          background-image: url(./static/bgr.svg);
        }
        .ant-card-head {
          margin-bottom: 10px;cure
        }
        .ant-tabs-content.ant-tabs-content-animated.ant-tabs-left-content {
          border: none !important;
        }
        .ant-card-head-title {
          margin-left: -8px;
        }
        .ant-card-head {
          margin-bottom: 25px;
      }
      .ant-dropdown-trigger {
        cursor: pointer;
      }
      .ant-btn-lg{
        border-radius:4px
      }
      .ant-input {
        border-radius:4px
      }
      .ant-tabs-nav .ant-tabs-tab{
        padding:12px 16px;
      }
      .ant-menu-item-selected {
        background-color: transparent !important;
      }
      .custom-table .ant-table-thead > tr > th{
        font-weight:700 !important;
      }
      .custom-btn {
        box-shadow: none !important;
        height: 39px !important;
        margin-top: 6px !important;
        cursor: pointer !important;
        display: flex !important;
        align-items: center !important;
        line-height: normal !important;
        text-decoration: none !important;
        text-align: center !important;
        border: 0 !important;
        font-size: 0.8em !important;
        font-family: Montserrat !important;
        padding: 0 !important;
        color: #ffffff !important;
        transition: 0.3s background-color ease-in-out !important;
        -webkit-font-smoothing: antialiased !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
        margin: 10px 0 !important;
        text-transform: uppercase !important;
        letter-spacing: 1px !important;
        font-weight: 600 !important;
        position: relative !important;
        margin-left: 19px !important;
        background-color: #ef3743 !important;
        background-repeat: no-repeat !important;
      }
      .custom-btn:before {
        content: " ";
        background: url(./static/left-red.png);
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        height: 39px;
        width: 20px;
        position: absolute;
        left: -14px;
        top: 0px;
        z-index: -1;
      }
      .custom-btn:after {
        content: " "  !important;
        background-image: url(./static/right-red.png) !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        display: block !important;
        height: 39px !important;
        width: 20px !important;
        position: absolute !important;
        right: -17px !important;
        z-index: -1 !important;
        top: 0px !important;
      }

      .custom-btn--center {        
        padding-left: 5px !important;
        padding-right: 5px !important;
      }

      .ant-tabs-nav .ant-tabs-tab{
        font-family: "Montserrat";        
        font-size: 18px;
        font-weight: normal;
        color: #1F5081;        
      }
      .ant-tabs-nav .ant-tabs-tab-hover{        
        color: #1F5081;
      }
      .ant-tabs-nav .ant-tabs-tab-active{        
        font-weight: 600;        
      }
      .ant-tabs-nav .ant-tabs-ink-bar{
        background-color:#1F5081;        
      }
      .ant-tabs-bar{
        margin:0        
      }
      .ant-tabs-tabpane{
        background-color: #F4F9FF;        
        border-right:1px solid #eee;        
        border-bottom:1px solid #eee;        
        border-left:1px solid #eee;        
      }
      .ant-input{       
        font-family: "Montserrat";
      }
     
      .ant-form-item-has-error .ant-form-item-explain{
        font-size: 15px;
      }
      .ant-form-item-label > label {
        font-size: 16px;
        font-weight: 400;
        font-family: "Montserrat";
        color:#343434;
      }
      .ant-input,
      .ant-select {
        border-color:#c9c9c9;
        font-size: 16px;
      }
      .ant-modal-footer .ant-btn{        
        font-family: "Montserrat" !important;
        font-weight:600;
        text-transform: uppercase;
      }
      .ant-modal-footer .ant-btn-primary{
        background-color:#ef3743 !important;                
      }
      .ant-modal-title{
        font-family: "Montserrat";
        font-size: 18px;    
        color: #1F5081;
        font-weight: 600;
      }           
      .form-input-group {
        width: 70%;
        position: relative;
        margin: 0 10%;
      }
      .form-input-field {
        font-weight: 400;
        font-family: "Montserrat";
        background-color: #f2f2f2;
        padding: 0.4em;
        border-radius: 4px;
      }
      .ant-modal-body{
        padding-bottom: 5px;
      }
      .ant-modal-footer{
        padding: 0;
        margin-left: -20px;
        margin-right: -20px;
        margin-top: 24px;        
      }
      .breadcrumb-link{
        font-size: 16px;
        font-weight: 600;
        color:rgb(4, 75, 115);
      }
      .breadcrumb-link a{       
        font-weight: normal;       
        color:#1F5081;
      }

      `}
    </style>
  </div>
);
export default Meta;
